import { DELIVERED_BY } from "./merchant";

export type DeliveredBy = keyof typeof DELIVERED_BY;

export type DeliveryMethodResponse = {
  id: string;
  priority: number;
  type: string;
  value: number;
  minTime: number;
  maxTime: number;
  mode: string;
  schedule: Schedule;
  title: string;
  subtitle: string;
  state: StateType;
  originalValue?: number;
  deliveredBy?: DeliveredBy;
  enabled?: boolean;
};

export type StateType = "ELIGIBLE" | "ELIGIBLE" | "NOT_ELIGIBLE_HIDDEN";

export type Schedule = {
  now: boolean;
  shifts: Shift[];
  timeSlots: TimeSlot[];
  selectedTimeSlot?: TimeSlot;
};

export type Shift = {
  dayOfWeek: string;
  endTime: string;
  interval: string;
  startTime: string;
};

export type TimeSlot = {
  availableLoad: string;
  date: string;
  endDateTime: string;
  endTime: string;
  id: string;
  isAvailable: boolean;
  originalPrice: number;
  price: number;
  startDateTime: string;
  startTime: string;
};

export type DeliveryMethodPayload = {
  id: string;
  maxTime: number;
  minTime: number;
  mode: string;
  schedule: Schedule;
  subtitle: string;
  title: string;
  type: string;
  value: number;
  deliveredBy: string;
};

export type DeliveryMethodJSON = {
  id: string;
  priority: number;
  type: string;
  value: number;
  minTime: number;
  maxTime: number;
  mode: string;
  schedule: Schedule;
  title: string;
  subtitle: string;
  state: StateType;
  originalValue?: number;
  deliveredBy?: DeliveredBy;
  enabled?: boolean;
};

export class DeliveryMethod {
  static fromApi(rawDeliveryMethod: DeliveryMethodResponse) {
    return new DeliveryMethod(
      rawDeliveryMethod.id,
      rawDeliveryMethod.priority,
      rawDeliveryMethod.type,
      rawDeliveryMethod.value,
      rawDeliveryMethod.minTime,
      rawDeliveryMethod.maxTime,
      rawDeliveryMethod.mode,
      rawDeliveryMethod.schedule,
      rawDeliveryMethod.title,
      rawDeliveryMethod.subtitle,
      rawDeliveryMethod.state,
      rawDeliveryMethod.originalValue,
      rawDeliveryMethod.deliveredBy,
      rawDeliveryMethod.enabled,
    );
  }

  static fromJSON(rawDeliveryMethod: DeliveryMethodJSON) {
    return new DeliveryMethod(
      rawDeliveryMethod.id,
      rawDeliveryMethod.priority,
      rawDeliveryMethod.type,
      rawDeliveryMethod.value,
      rawDeliveryMethod.minTime,
      rawDeliveryMethod.maxTime,
      rawDeliveryMethod.mode,
      rawDeliveryMethod.schedule,
      rawDeliveryMethod.title,
      rawDeliveryMethod.subtitle,
      rawDeliveryMethod.state,
      rawDeliveryMethod.originalValue,
      rawDeliveryMethod.deliveredBy,
      rawDeliveryMethod.enabled,
    );
  }

  constructor(
    public id: string,
    public priority: number,
    public type: string,
    public value: number,
    public minTime: number,
    public maxTime: number,
    public mode: string,
    public schedule: Schedule,
    public title: string,
    public subtitle: string,
    public state: StateType,
    public originalValue?: number,
    public deliveredBy?: DeliveredBy,
    public enabled?: boolean,
  ) {}

  toJSON(): DeliveryMethodJSON {
    return {
      id: this.id,
      priority: this.priority,
      type: this.type,
      value: this.value,
      minTime: this.minTime,
      maxTime: this.maxTime,
      mode: this.mode,
      originalValue: this.originalValue,
      deliveredBy: this.deliveredBy,
      enabled: this.enabled,
      schedule: this.schedule,
      title: this.title,
      subtitle: this.subtitle,
      state: this.state,
    };
  }

  toPayload(): DeliveryMethodPayload {
    return {
      id: this.id,
      maxTime: this.maxTime,
      minTime: this.minTime,
      mode: this.mode,
      schedule: this.schedule,
      subtitle: this.subtitle,
      title: this.title,
      type: this.type,
      value: this.value,
      deliveredBy: this.deliveredBy || "",
    };
  }

  isDeliveryMode() {
    return this.mode === "DELIVERY";
  }

  isTakeoutMode() {
    return this.mode === "TAKEOUT";
  }
}
